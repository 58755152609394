@import-normalize;

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
blockquote,
form,
ul,
ol,
dl {
  margin: 0;
}

ul {
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
@font-face {
  font-family: "AvenirLTPro";
  src: url("/fonts/AvenirLTPro/AvenirLTPro35Light.woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "AvenirLTPro";
  src: url("/fonts/AvenirLTPro/AvenirLTPro45Book.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "AvenirLTPro";
  src: url("/fonts/AvenirLTPro/AvenirLTPro65Medium.woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "AvenirLTPro";
  src: url("/fonts/AvenirLTPro/AvenirLTPro85Heavy.woff2");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

:root {
  /* ----------------- Typography scale system: ----------------- */
  /* https://utopia.fyi/type/calculator/?c=320,14.5,1.1035,2560,26,1.175,7,5,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

  --step--5: clamp(0.55rem, calc(0.53rem + 0.12vw), 0.73rem);
  --step--4: clamp(0.61rem, calc(0.58rem + 0.17vw), 0.85rem);
  --step--3: clamp(0.67rem, calc(0.63rem + 0.23vw), 1rem);
  --step--2: clamp(0.74rem, calc(0.68rem + 0.31vw), 1.18rem);
  --step--1: clamp(0.82rem, calc(0.74rem + 0.4vw), 1.38rem);
  --step-0: clamp(0.91rem, calc(0.8rem + 0.51vw), 1.63rem);
  --step-1: clamp(1rem, calc(0.87rem + 0.65vw), 1.91rem);
  --step-2: clamp(1.1rem, calc(0.94rem + 0.81vw), 2.24rem);
  --step-3: clamp(1.22rem, calc(1.01rem + 1.01vw), 2.64rem);
  --step-4: clamp(1.34rem, calc(1.09rem + 1.25vw), 3.1rem);
  --step-5: clamp(1.48rem, calc(1.18rem + 1.54vw), 3.64rem);
  --step-6: clamp(1.64rem, calc(1.26rem + 1.89vw), 4.28rem);
  --step-7: clamp(1.81rem, calc(1.35rem + 2.3vw), 5.03rem);

  /*  ----------------- Space props:  ----------------- */
  /* 3.75rem(60px) @ 20rem(320px) increasing to 11.25rem(180px) @ 160rem(2560px) */
  --x: clamp(3.75rem, calc(3.75rem + ((1vw - 0.2rem) * 5.3571)), 11.25rem);

  /* Safari resize fix */
  min-height: 0vw;

  /* 4.6875rem(75px) @ 20rem(320px) increasing to 6.875rem(110px) @ 160rem(2560px) */
  --header-height: clamp(
    4.6875rem,
    calc(4.6875rem + ((1vw - 0.2rem) * 1.5625)),
    6.875rem
  );

  --secondary-menu-height: min(50px,15vw);
  --footer-height: max(40px, calc(var(--header-height) / 2));

  /* 1.625rem(26px) @ 20rem(320px) increasing to 5.125rem(82px) @ 160rem(2560px) */
  --tabs-height: clamp(1.625rem, calc(1.625rem + ((1vw - 0.2rem) * 2.5)), 5.125rem);

  /* 11.6875rem(187px) @ 20rem(320px) increasing to 21.8125rem(349px) @ 160rem(2560px) */
  --default-padding: clamp(
    11.6875rem,
    calc(11.6875rem + ((1vw - 0.2rem) * 7.2321)),
    21.8125rem
  );


  /* Colors: */
  --color-ff2f70: #ff2f70;
  --color-ff3b1e: #ff3b1e;
  --color-000000: #000000;
  --color-646464: #646464;
  --color-dcdcdc: #dcdcdc;
  --color-959595: #959595;
  --color-707070: #707070;
  --color-ffffff: #ffffff;
  --color-2b2e34: #2b2e34;
  --color-f9f9f9: #f9f9f9;

  --partner-know-more:  #b6c4e4;

  --studio-text-background-color: #F9323A;
  /* --studio-text-foreground-color-dark: #000000; */
  --studio-text-foreground-color-dark: #ffffff;
  --studio-text-foreground-color-lite: #ffffff;

  /* Font/text values */
  --font-style-normal: normal;
  --font-style-oblique: oblique;
  --font-weight-300: 300;
  --font-weight-900: 900;
  --font-weight-normal: normal;
  --font-weight-medium: medium;
  --font-size-18: 18px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-36: 36px;
  --font-size-46: 46px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-72: 72px;
  --font-size-80: 80px;
  --font-size-160: 160px;
  --character-spacing-0: 0px;
  --character-spacing-4-8: 4.8px;
  --character-spacing-1-8: 1.8px;
  --character-spacing--0-16: -0.16px;
  --character-spacing--0-14: -0.14px;
  --line-spacing-24: 24px;
  --line-spacing-36: 36px;
  --line-spacing-46: 46px;
  --line-spacing-48: 48px;
  --line-spacing-58: 58px;
  --line-spacing-64: 64px;
  --line-spacing-66: 66px;
  --line-spacing-96: 96px;
  --line-spacing-192: 192px;
  --text-transform-uppercase: uppercase;
}

html,
body {
  background-color: var(--color-ffffff);
  color: var(--color-000000);
  height: 100%;
  /* use Helvetica/Arial as fallbacks */
  font-family: AvenirLTPro, Helvetica, Arial, sans-serif;
  font-weight: 300;
}

#__next {
  height: 100%;
}

a {
  text-decoration: none;
}
